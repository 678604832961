<template>
    <v-container fluid>
        <v-row>
            <v-col v-if="mostrar_lista_alunos" :cols="colLista">
                <v-card elevation="2" class="card" :loading="listaAlunos_loading" height="100%">
                    <v-toolbar dense flat>
                        <v-toolbar-title>Alunos</v-toolbar-title>

                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click="carregarListaAlunos" class="c_atualizar" v-bind="attrs" v-on="on">
                                    <v-icon color="secondary">mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Atualizar</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon>
                                    <v-icon color="secondary" @click="dialog = true" v-bind="attrs" v-on="on">mdi-magnify
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Pesquisar</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click="novo_aluno" v-bind="attrs" v-on="on">
                                    <v-icon color="secondary">mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Novo</span>
                        </v-tooltip>
                    </v-toolbar>
                    <v-list nav dense>
                        <v-list-item-group v-model="aluno_selecionado" color="primary">
                            <v-list-item v-for="item in listaAlunos" :key="item.id" @click="click_aluno(item.id)">
                                <v-list-item-icon>
                                    <v-icon :class="item.ie_sexo == 'F' ? 'cMenina' : 'cMenino'">mdi-star</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title v-text="item.ds_nome"></v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-chip x-small>{{ JSON.parse(item.obj_turma).ds_turma }}</v-chip>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-col>
            <v-col v-if="mostrar_detalhes" :cols="colDetalhes">
                <v-card elevation="2" class="card" height="100%" :loading="detalhes_aluno_loading"
                    v-if="this.detalhes_aluno != undefined">

                    <v-toolbar dense flat>
                        <!--<v-toolbar-title v-if="this.detalhes_aluno.id == undefined">Novo aluno</v-toolbar-title>
                        <v-toolbar-title v-if="this.detalhes_aluno.id > 0">Detalhes</v-toolbar-title>
                        -->
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click="tela_compartilhada" v-bind="attrs" v-on="on" v-if="restaurar">
                                    <v-icon color="secondary">mdi-window-restore</v-icon>
                                </v-btn>
                            </template>
                            <span>Restaurar</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click="tela_lista_maximizado" v-if="maximizar" v-bind="attrs" v-on="on">
                                    <v-icon color="secondary">mdi-window-maximize</v-icon>
                                </v-btn>
                            </template>
                            <span>Maximizar</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click="tela_detalhes_fechada" v-bind="attrs" v-on="on">
                                    <v-icon color="secondary">mdi-close</v-icon>
                                </v-btn>
                            </template>
                            <span>Fechar</span>
                        </v-tooltip>

                        <v-menu offset-y left transition="slide-y-transition" v-if="mostrar_lista_alunos == true">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn dark icon v-bind="attrs" v-on="on">
                                    <v-icon color="secondary">mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="apagar_aluno(0)">
                                    <v-list-item-title>
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-list-item-title>
                                    <v-list-item-actions>
                                        Apagar
                                    </v-list-item-actions>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-toolbar>

                    <FormAluno @refresh="carregarListaAlunos()" :objeto="this.detalhes_aluno"></FormAluno>

                </v-card>
            </v-col>
        </v-row>

        <!-- Filtro de pesquisa -->
        <v-dialog v-model="dialog" max-width="600px">
            <v-card>
                <v-toolbar dense elevation="0">
                    <v-toolbar-title>Filtro de pesquisa</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-btn icon @click="dialog = false">
                        <v-icon color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="v_filtroNome" label="Nome do aluno" autofocus></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="filtrar_aluno">
                        Filtrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog do filtro -->
        <v-dialog v-model="v_filtroResultado" max-width="400">
            <v-card>
                <v-card-title class="subtitle-1" align="center">
                    Nenhum registro encontrado !
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn text color="primary" @click="v_filtroResultado = false">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<style scoped>
.card {}

.c_atualizar {
    margin-right: 25px;
}

.cMenina {
    color: rgb(238, 192, 192)
}

.cMenino {
    color: rgb(133, 172, 133)
}
</style>

<script>
import axios from 'axios';
import FormAluno from '@/components/FormAluno.vue'

export default {
    name: 'AlunoPage',
    components: { FormAluno },
    data: () => ({
        dialog: false,
        v_filtroNome: null,

        listaAlunos: [],
        listaAlunos_loading: false,

        detalhes_aluno: null,
        detalhes_aluno_loading: false,

        aluno_selecionado: null,

        mostrar_detalhes: false,
        mostrar_lista_alunos: true,

        colLista: 12,
        colDetalhes: 0,

        v_filtroResultado: false,

    }),
    computed: {
        maximizar() {
            if ((this.mostrar_lista_alunos == true) & (this.colDetalhes == 7)) {
                return true
            } else {
                return false
            }
        },
        restaurar() {
            console.log(this.mostrar_lista_alunos)
            if ((this.mostrar_detalhes == true) & (this.colDetalhes == 12)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
        dialog() {
            this.v_filtroNome = '';
        }
    },
    methods: {
        tela_compartilhada() {
            this.colLista = 5
            this.colDetalhes = 7
            this.mostrar_detalhes = true
            this.mostrar_lista_alunos = true
        },
        tela_detalhes_fechada() {
            this.colLista = 12
            this.colDetalhes = 0
            this.mostrar_detalhes = false
            this.mostrar_lista_alunos = true
        },
        tela_lista_maximizado() {
            this.colLista = 0
            this.colDetalhes = 12
            this.mostrar_detalhes = true
            this.mostrar_lista_alunos = false
        },
        novo_aluno() {
            this.tela_lista_maximizado()
            this.aluno_selecionado = null
            this.detalhes_aluno = {}
        },
        apagar_aluno(id) {
            console.log(id)
        },
        carregarListaAlunos() {
            this.listaAlunos_loading = true
            this.aluno_selecionado = null
            axios.get(sessionStorage.getItem('url').concat('alunos/1'), {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + sessionStorage.getItem('TokenAcesso')
                }
            }).then(response => {
                this.listaAlunos = response.data;
                this.tela_detalhes_fechada()
            }
            ).finally(
                this.listaAlunos_loading = false
            );
        },

        click_aluno(id) {
            this.tela_compartilhada();
            this.detalhes_aluno_loading = true
            axios.get(sessionStorage.getItem('url').concat('alunos/1/').concat(id), {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + sessionStorage.getItem('TokenAcesso')
                }
            }).then(response => {
                this.detalhes_aluno = response.data[0];
            }
            ).finally(
                this.detalhes_aluno_loading = false
            );
        },

        filtrar_aluno() {
            axios.get(sessionStorage.getItem('url').concat('alunos/1/nome/').concat(this.v_filtroNome), {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + sessionStorage.getItem('TokenAcesso')
                }
            }).then(response => {
                this.listaAlunos = response.data;
                this.tela_detalhes_fechada();
                this.dialog = false;
            }).catch(error => {
                if (error.response.status == 404) {
                    this.dialog = false
                    this.v_filtroResultado = true
                }
            }
            ).finally(
                this.loading_alunos = false
            )
        }

    },
    mounted: function () {
        this.tela_lista_maximizado();
        this.carregarListaAlunos();
    }
}
</script>
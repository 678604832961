import Vue from "vue";
import axios from "axios";
import LoginPage from "@/pages/LoginPage";

Vue.use(auth);

export default async function auth(next) {
  var retorno = false
  retorno = await verificaToken();  
  if (retorno != true) {
        return next({
      path: "/",
      name: "Login",
      component: LoginPage,
    });
  }

  return next();
}

async function verificaToken() {  
  var retorno = false
  const config = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("TokenAcesso"),
    },
  };
  
  await axios
    .get(sessionStorage.getItem('url').concat('usuarios/verificaToken'), config)
    .then(() => {          
      retorno = true
    })
    .catch(() => {      
    });

    return retorno;
}

<template>
  <v-container fluid>
    <v-row>
      <v-col>
          <v-card elevation="2" class="card">
            <v-card-title>Conversas recentes</v-card-title>
            <v-card-subtitle>....</v-card-subtitle>
          </v-card>

          <v-card elevation="2" class="card">
            <v-card-title>Turmas</v-card-title>
            <v-card-subtitle>....</v-card-subtitle>
          </v-card>
      </v-col>
      <v-col>
        <v-card elevation="2" class="card">
            <v-card-title>Alunos</v-card-title>
            <v-card-subtitle>....</v-card-subtitle>
          </v-card>
      </v-col>
      <v-col>
        <v-card elevation="2" class="card">
            <v-card-title>Contas a pagar</v-card-title>
            <v-card-subtitle>....</v-card-subtitle>

          </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<style scoped>
.card {
  margin-bottom: 30px;
}
</style>

<script>
export default {
  name: "PainelDeControle",
  data: () => ({
    subMenu: true,
  })
}
</script>
  
import Vue from 'vue'
import Router from 'vue-router'
import PainelDeControlePage from '@/pages/PainelDeControle'
import LoginPage from '@/pages/LoginPage'
import AlunoPage from '@/pages/AlunoPage.vue'
import auth from "@/middleware/auth"

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/painel',
      name: 'Painel',
      component: PainelDeControlePage,
      beforeEnter: (to, from, next) => {
        auth(next)
      }      
    },
    {
      path: '/aluno',
      name: 'Aluno',
      component: AlunoPage,
      beforeEnter: (to, from, next) => {
        auth(next)
      }
    }
  ]
})
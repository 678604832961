import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        ligth: true,
        themes: {
          light: {
            primary: '#7f6468',
            secondary: '#00beef',
            accent: '#8c9eff',
            error: '#b71c1c',
          },
          dark: {
            primary: '#7f6468',
            secondary: '#b0bec5',
            accent: '#8c9eff',
            error: '#b71c1c',
          },      
        },
      },
});

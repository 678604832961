<template>
    <v-form>
        <v-tabs fixed-tabs class="container" color="primary">
            <v-tab>Identificação</v-tab>
            <v-tab>Endereço</v-tab>
            <v-tab>Saúde</v-tab>
            <v-tab>Portaria</v-tab>
            <v-tab>Observação</v-tab>

            <v-tab-item class="container">
                <v-row>
                    <v-text-field v-model="v_nome" label="Aluno" autofocus dense outlined clearable></v-text-field>
                </v-row>
                <v-row>
                    <v-combobox dense outlined v-model="v_turma" label="Turma" :items="listaTurmas" item-text="ds_turma"
                        item-value="id">
                    </v-combobox>
                </v-row>
                <v-row>
                    <v-text-field v-model="v_sexo" label="Sexo" dense outlined clearable></v-text-field>
                </v-row>
            </v-tab-item>
            <v-tab-item>
                2
            </v-tab-item>
            <v-tab-item>
                3
            </v-tab-item>
        </v-tabs>

        <v-btn text @click="salvar_aluno" color="primary">Salvar</v-btn>

    </v-form>
</template>

<style scoped>
.container {
    padding: 30px;
    color: blue;
}
</style>

<script>
import axios from 'axios';

export default {
    name: 'FormConta',
    props: ['objeto'],
    emits: ['refresh'],
    data() {
        return {
            v_nome: null,
            v_sexo: null,
            v_turma: null,
            listaTurmas: null
        }
    },
    mounted: function () {
        this.FormularioListaTurmas();
        this.FormularioEdit();
    },
    methods: {
        salvar_aluno() {
            const param = { id_escola: 1, id_turma: this.v_turma.id, dt_nascimento: new Date(), ds_nome: this.v_nome, ie_sexo: this.v_sexo };
            console.log(param)
            axios.post(sessionStorage.getItem('url').concat("alunos"), param, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + sessionStorage.getItem('TokenAcesso')
                }
            }).then(response => {
                console.log(response.data)
                this.$emit('refresh');
            }).catch(error => {
                console.log(error);
            });
        },
        FormularioEdit() {
            this.v_nome = this.objeto.ds_nome,
                this.v_sexo = this.objeto.ie_sexo,
                this.v_turma = JSON.parse(this.objeto.obj_turma)
        },
        FormularioListaTurmas() {
            axios.get(sessionStorage.getItem('url').concat('turmas/1'), {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + sessionStorage.getItem('TokenAcesso')
                }
            }).then(response => {
                this.listaTurmas = response.data;
            });
        }
    },
    watch: {
        objeto() {
            if (this.objeto.id == undefined) {
                this.v_turma = { id: null, ds_turma: null }
            }
            this.FormularioListaTurmas();
            this.FormularioEdit();
        }

    }
}
</script>
